<template>
  <div>
    <button @click="syncEventBadges()">Sync Event Badges</button>
    <button @click="syncOrgBadges()">Sync Org Badges</button>
    <button @click="syncBadgeCount()">Sync Badge Count</button>
    <div>
      <button @click="syncVisitors('MpnsP9E3kqShxAugiWvq')">
        Sync Armada Visitors
      </button>
      <button @click="syncVisitors('CRsoZHMIDEy3hNfWojom')">
        Sync Fashion Visitors
      </button>
      <h5>{{ visitorCount }}</h5>
    </div>

    <div>
      <button @click="syncKPI('MpnsP9E3kqShxAugiWvq')">Sync Armada KPI</button>
      <button @click="syncKPI('jOvSMpMn46lRxUX25TuE')">Sync Banking KPI</button>
      <button @click="syncKPI('CRsoZHMIDEy3hNfWojom')">Sync Fashion KPI</button>
      <div v-if="syncingKPI">
        <img src="@/assets/img/Components/Loading/loader-dark.svg" alt="" />
        <span v-if="kpiFetchCount">Fetched {{ kpiFetchCount }}</span>
        <div v-if="failedOrgs.length > 0">
          <h5>Failed</h5>
          <div v-for="(failed, index) in failedOrgs" :key="index">
            <span>{{ failed.oid }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GET, db, UPDATE } from "@/firebase.js";

export default {
  name: "Syncer",
  data: function () {
    return {
      //eventId: "MpnsP9E3kqShxAugiWvq",
      //eventId: "jOvSMpMn46lRxUX25TuE",
      eventId: "CRsoZHMIDEy3hNfWojom",
      kpiFetchCount: 0,
      syncingKPI: false,
      failedOrgs: [],
      visitorCount: null,
    };
  },
  methods: {
    syncBadgeCount: function () {
      let col = db.collection("orgs");
      GET(col).then((orgs) => {
        orgs.forEach((org) => {
          let doc = db.doc("orgs/" + org.id);
          let badgeCount = org.data().badges ? org.data().badges.length : 0;
          UPDATE(doc, {
            ["counts.badges"]: badgeCount,
          });
        });
      });
    },
    syncVisitors: function (eventId) {
      let col = db.collection("events/" + eventId + "/visitors");
      col = col.where("date", ">", new Date("2020-11-09"));
      GET(col, true).then((visitors) => {
        this.visitorCount = visitors.docs.length;
      });
    },
    syncOrgBadges: async function () {
      let orgs = await this.getAllOrgs();
      orgs.forEach(async (org) => {
        let badgesIds = await this.getAllBadgesForOrg(org.id);
        let doc = db.collection("orgs").doc(org.id);
        UPDATE(doc, {
          badges: badgesIds,
        });
      });
    },
    getAllBadgesForOrg: function (orgId) {
      return new Promise((resolve) => {
        let col = db.collection("promises").where("org", "==", orgId);
        GET(col, true).then((promises) => {
          let promisesIds = [];
          promises.forEach((promise) => {
            if (!promise.data().badge) return;
            promisesIds.push(db.doc("badges/" + promise.data().badge.id));
            resolve(promisesIds);
          });
        });
      });
    },
    getAllOrgs: function (eventId = null, limit = null, startAfter = null) {
      return new Promise((resolve) => {
        let col = db.collection("orgs");
        if (eventId) col = col.where("events." + eventId, "==", true);
        if (limit) {
          col = col.limit(limit);
          // col = col.orderBy("updated", "asc");
          col = col.orderBy("name", "asc");
        }
        if (startAfter) col = col.startAfter(startAfter);
        GET(col, true).then((orgs) => resolve(orgs));
      });
    },
    syncEventBadges: function () {
      console.log("Sync starting");
      let badgeSyncCount = 0;
      let connectedBadges = [];
      let col = db.collection("badges");
      GET(col).then((badges) => {
        badges.forEach(async (badge) => {
          badgeSyncCount++;
          console.log(badgeSyncCount);
          let badgeIsConnected = await this.badgeIsConnectedToEvent(badge.id);
          if (badgeIsConnected) connectedBadges.push(badge.id);
          console.log(badgeSyncCount, badges.size);
          if (badges.size === badgeSyncCount) {
            this.addBadgesToEvent(connectedBadges);
          }
        });
      });
    },
    addBadgesToEvent: function (connectedBadges) {
      let doc = db.collection("events").doc(this.eventId);
      UPDATE(doc, {
        badges: connectedBadges,
      });
    },
    badgeIsConnectedToEvent: function (badgeId) {
      return new Promise((resolve) => {
        let col = db
          .collection("orgs")
          .where("badges", "array-contains", db.doc("badges/" + badgeId));
        col = col.where("events." + this.eventId, "==", true);
        GET(col).then((orgs) => {
          resolve(!orgs.empty);
        });
      });
    },
    orgAttendsEvent: function (org) {
      return new Promise((resolve) => {
        let doc = db.doc("orgs/" + org);
        GET(doc).then((org) => {
          if (!org.exists || !org.data()) return resolve(false);
          if (!org.data().events) return resolve(false);
          else {
            return resolve(
              !org.data().events.some((event) => event === this.eventId)
            );
          }
        });
      });
    },
    addKPIToDB: function (org) {
      let doc = db.doc("orgs/" + org.id);
      UPDATE(doc, {
        KPI: org.KPI,
        updated: new Date(),
      }).then(() => {
        console.log(org.oid, JSON.stringify(org.KPI));
        this.kpiFetchCount++;
      });
    },
    setTrendData: function (org) {
      for (let key of Object.keys(org.KPI)) {
        let trend;
        if (key.split("_")[1] === "eq") {
          trend = org.KPI[key]["2020"].raw - org.KPI[key]["2017"].raw;
        } else if (
          key.split("_")[1] === "ghg" &&
          org.KPI[key]["2020"].raw &&
          org.KPI[key]["2017"].raw
        ) {
          trend = org.KPI[key]["2020"].raw / org.KPI[key]["2017"].raw - 1;
          trend = parseFloat((trend * 100).toFixed(0));
        }
        if (trend) org.KPI[key]["trend"] = trend;
        else org.KPI[key]["trend"] = NaN;
      }
      this.addKPIToDB(org);
    },
    getSheetColumn: function (org) {
      this.gapi.client.sheets.spreadsheets.values
        .get({
          spreadsheetId: "1EBjXVMqDlgZvTp8CpqpFHQ0CJcoB2fAlv0iSaYRTp10",
          range: "B1:S1",
        })
        .then((response) => {
          let result = response.result;
          let itemCount = 0;
          let savedKPI = null;
          result.values.forEach((items) => {
            items.forEach((item) => {
              let fragments = item.split("/");
              let kpiName = fragments[0];
              let innerFragments = fragments[1].split(":");
              let year = innerFragments[0];
              let type = innerFragments[1];
              if (type === "tco2e" || kpiName === "ghg") return itemCount++;
              if (!org.KPI[kpiName]) {
                org.KPI[kpiName] = {};
              }
              if (!org.KPI[kpiName][year]) {
                org.KPI[kpiName][year] = {};
              }
              if (kpiName.split("_")[1] === "eq") {
                if (type === "total_hc") {
                  savedKPI = org.kpiValues[itemCount];
                } else if (type === "women_hc") {
                  let val = org.kpiValues[itemCount] / savedKPI;
                  org.KPI[kpiName][year].raw = parseInt(val * 100);
                  if (val > 0.5 && val) val = 1.0 - val;
                  org.KPI[kpiName][year].normalized = parseInt(val * 100);
                }
              } else if (kpiName.split("_")[1] === "ghg") {
                if (!org.kpiValues[itemCount])
                  org.KPI[kpiName][year].raw = !org.kpiValues[itemCount];
                org.KPI[kpiName][year].raw = parseFloat(
                  org.kpiValues[itemCount]
                );
              }
              itemCount++;
            });
          });
          this.setTrendData(org);
        })
        .catch(() => {
          this.failedOrgs.push(org);
          console.log(org);
        });
    },
    getSheetValues: function (index, org) {
      let range = "B" + index + ":S" + index;
      this.gapi.client.sheets.spreadsheets.values
        .get({
          spreadsheetId: "1EBjXVMqDlgZvTp8CpqpFHQ0CJcoB2fAlv0iSaYRTp10",
          range: range,
        })
        .then((response) => {
          let result = response.result;
          result.values.forEach((items) => {
            items.forEach((item) => {
              org.kpiValues.push(parseFloat(item));
            });
          });
          this.getSheetColumn(org);
        })
        .catch(() => {
          this.failedOrgs.push(org);
          console.log(org);
        });
    },
    findRowIndex: function (org) {
      this.gapi.client.sheets.spreadsheets.values
        .get({
          spreadsheetId: "1EBjXVMqDlgZvTp8CpqpFHQ0CJcoB2fAlv0iSaYRTp10",
          range: "A2:A75",
        })
        .then((response) => {
          let rowIndex = null;
          let rowCount = 2;
          let result = response.result;
          result.values.forEach((items) => {
            if (items[0] === org.oid) return (rowIndex = rowCount);
            rowCount++;
          });
          if (rowIndex === null) {
            return console.log("Company does not exist in sheet");
          }

          this.getSheetValues(rowIndex, org);
        });
    },
    syncKPI: async function (eventId) {
      this.syncingKPI = true;
      let lastFetched = null;
      let orgFetchInterval = setInterval(async () => {
        let orgs = await this.getAllOrgs(eventId, 10, lastFetched);
        if (orgs.empty) {
          alert("All companies synced");
          this.syncingKPI = false;
          return clearInterval(orgFetchInterval);
        }
        lastFetched = orgs.docs[orgs.docs.length - 1];
        orgs.forEach((org) => {
          let orgObj = {
            oid: org.data().oid,
            kpiValues: [],
            KPI: {},
            id: org.id,
          };
          this.findRowIndex(orgObj);
        });
      }, 5000);
    },
    authGapi: async function () {
      this.gapi = await this.$gapi.getGapiClient();
      console.log("Gapi", this.gapi);
      if (this.$gapi.isAuthenticated() !== true) {
        this.$gapi.login().catch((err) => {
          alert(JSON.stringify(err));
        });
      } else {
        console.log("User is authenticated");
      }
    },
  },
  created: function () {
    this.authGapi();
  },
};
</script>

<style>
</style>